<template>
  <v-container fluid>
    <!-- editor -->
    <editor
      :currencies="currencies"
      :initial="dataItem"
      :pageMode="pageMode"
    ></editor>
    <!-- end -->
  </v-container>
</template>

<script>
import Editor from "../_components/editor.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    Editor,
  },
  computed: {
    ...mapGetters(["user"]),
  },
  data: () => ({
    dataItem: {},
    currencies: [],
    pageMode: "view",
  }),
  watch: {
    "$route.params.id": {
      handler: "getDealerMaster",
      immediate: true,
    },
  },
  methods: {
    getDealerMaster(val) {
      const self = this;
      self.loading = true;
      this.$store
        .dispatch("get", `/dealer_masterdata/${val}`)
        .then((res) => {
          self.dataItem = res.ResponseData;
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },

    getCurrencies() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/currency`)
        .then((res) => {
          self.currencies = res;
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
  },
  created() {
    this.getCurrencies();
  },
};
</script>